import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import user from '../../assets/images/testimonial-user.png';

function TestimonialHomeOne({ className }) {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section
            className={`appie-testimonial-area pt-100 pb-160 ${className || ''}`}
            id="testimonial"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                            <span
                                className="prev slick-arrow"
                                style={{ display: 'block' }}
                                onClick={sliderNext}
                                role="button"
                                tabIndex="0"
                            >
                                <i className="fal fa-arrow-left" />
                            </span>
                            <Slider ref={sliderRef} dots arrows={false}>
                                <div className="appie-testimonial-item text-left">
                                    <div className="author-info">
                                        <img src={user} alt="" />
                                        <h5 className="title">Oil Industry</h5>
                                        <span></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                        <dt>
                                        <li>Budget consolidation from 60 days to 2 days.</li>
                                        <li>Executive Director’s Monthly Report (e.g. total sales by product).</li>
                                        <li>Management indicators for different business units.</li>
                                        <li>Strong focus on the operational level.</li>
                                        <li>Daily production information.</li>
                                        </dt>
                                        </p>
                                        
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-left">
                                    <div className="author-info">
                                        <img src={user} alt="" />
                                        <h5 className="title">Beverages Industry</h5>
                                        <span></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                        <dt>
                                            <li>1 Budget per year to 9 Forecasts and 4 Budgeting Scenarios per year.</li>
                                            <li>RAW material and BOM modules at SKU level (packaging, flavor and geographical region).</li>
                                            <li>Cashflow and Income statement metrics with specific notifications.</li>
                                            <li>What-if scenarios comparison.</li>
                                        
                                            
                                        </dt>
                                        
                                        </p>
                                                  </div>
                                </div>
                                <div className="appie-testimonial-item text-left">
                                    <div className="author-info">
                                        <img src={user} alt="" />
                                        <h5 className="title">Real Estate Group</h5>
                                        <span></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                        <dt>
                                            <li>1 Budget per year to 4 Forecasts and 2 Budgeting Scenarios per year.</li>
                                            <li>Expenses, revenue and CAPEX planning.</li>
                                            <li>KPIs follow-up: Average daily rate, Revenue per available,Average length of stay.</li>                                                                                                                                          
                                            <li> What-if scenarios comparison.</li>
                                        
                                            </dt>
                                        
                                        </p>
                                        
                                    </div>
                                    </div>
                            </Slider>
                            <span
                                onClick={sliderPrev}
                                role="button"
                                tabIndex="-1"
                                className="next slick-arrow"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
