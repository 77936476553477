import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import AboutCapex from './AboutCapex';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import Drawer from '../Mobile/Drawer';

function Capex() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader drawer={drawer}  action={drawerAction.toggle} />
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-top-title">
                                <h2 className="title">Capex</h2>
                                <p>The capex model allows planning and having a complete vision of the company's assets. The main objective is to simplify the monitoring of capital costs and to know the depreciation figures of those assets through analytical reports.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="capex-appie-about-page-content">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AboutCapex />
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Capex;
