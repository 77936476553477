import React from 'react';
import IconOne from '../../assets/images/icon/1.png';
import IconTwo from '../../assets/images/icon/2.png';
import IconThree from '../../assets/images/icon/3.png';
import IconFour from '../../assets/images/icon/4.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                
The proven solution that  <br />supports the financial planning and budgeting process
                            </h3>
                            <p>
Flexible, modular, agile: choose how to improve your management. </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="">
                                <img src={IconOne} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">Modular</h4>
                            <p>Bilt-in and optional scalable modules.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="">
                                <img src={IconTwo} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">World Class</h4>
                            <p>Save up to 60% on development costs.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="">
                                <img src={IconThree} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">Information Quality</h4>
                            <p>Various projects, real and probable scenarios.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="">
                                <img src={IconFour} alt="" />
                                <span></span>
                            </div>
                            <h4 className="appie-title">Support</h4>
                            <p>Follow-up in the implementation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
