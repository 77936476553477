import React, { useEffect, useState } from "react";
// import blogImg1 from '../../assets/images/blog/1.jpg';
// import blogImg2 from '../../assets/images/blog/2.jpg';
// import blogImg3 from '../../assets/images/blog/3.jpg';
import { getBlogs } from "../../services/blogService";

function Blogs() {

    const [blogs, setBlogs] = useState([]);


    useEffect(() => {
        getBlogs().then(setBlogs);
    }, []);

    return (
        <>
            <div className="row">
                {blogs.map(blog => (
                    <div className="col-lg-6" key={blog.id}>
                        <div className="post-item-1">
                            <img src={blog.backgroundImage} alt="" />
                            <div className="b-post-details">

                                <h3>
                                    <a href={`/blog/${blog.id}`}>
                                        {blog.nombre}
                                    </a>
                                </h3>
                                <a className="read-more" href={`/blog/${blog.id}`}>
                                    Read More<i className="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
}

// <div className="row">
//     <div className="col-lg-6">
//         <div className="post-item-1">
//             <img src={blogImg1} alt="" />
//             <div className="b-post-details">
//
//                 <h3>
//                     <a href="/blog/single-news">
//                         The STM in figures
//                     </a>
//                 </h3>
//                 <a className="read-more" href="/blog/single-news">
//                     Read More<i className="fal fa-arrow-right"></i>
//                 </a>
//             </div>
//         </div>
//     </div>
//     <div className="col-lg-6">
//         <div className="post-item-1">
//             <img src={blogImg2} alt="" />
//             <div className="b-post-details">
//
//                 <h3>
//                     <a href="/blog/single-news">
//                         Banking as a service
//                     </a>
//                 </h3>
//                 <a className="read-more" href="/blog/single-news">
//                     Read More<i className="fal fa-arrow-right"></i>
//                 </a>
//             </div>
//         </div>
//     </div>
//     <div className="col-lg-6">
//         <div className="post-item-1">
//             <img src={blogImg3} alt="" />
//             <div className="b-post-details">
//
//                 <h3>
//                     <a href="/blog/single-news">
//                         The digital experience as a competitive advantage
//                     </a>
//                 </h3>
//                 <a className="read-more" href="/blog/single-news">
//                     Read More<i className="fal fa-arrow-right"></i>
//                 </a>
//             </div>
//         </div>
//     </div>
//
// </div>

export default Blogs;
