import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Gartner from './components/Gartner';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
// import HomeDark from './components/HomeDark';
// import HomeEight from './components/HomeEight';
// import HomeFive from './components/HomeFive';
// import HomeFour from './components/HomeFour';
 import HomeOne from './components/HomeOne';
// import HomeRtl from './components/HomeRtl';
// import HomeSeven from './components/HomeSeven';
// import HomeSix from './components/HomeSix';
// import HomeThree from './components/HomeThree';
// import Hometwo from './components/HomeTwo';
import ModuleBalance from './components/Module/Balance';
import ModuleCapex from './components/Module/Capex';
import ModuleOpex from './components/Module/Opex';
import ModuleRevenue from './components/Module/Revenue';
import ModuleWorkforce from './components/Module/Workforce';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import StmFigures from './components/News/StmFigures';
// import Service from './components/Service';
// import Shops from './components/Shops';
// import ShopDetails from './components/Shops/Details';

function MyRoutes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<HomeOne />} />
                            <Route path="/module-workforce" element={<ModuleWorkforce />} />
                            <Route path="/module-capex" element={<ModuleCapex />} />
                            <Route path="/module-revenue" element={<ModuleRevenue />} />
                            <Route path="/module-opex" element={<ModuleOpex />} />
                            <Route path="/module-balance-scorecard" element={<ModuleBalance />} />
                            {/* <Route path="/home-two" element={<Hometwo />} />
                            <Route path="/home-three" element={<HomeThree />} />
                            <Route path="/home-four" element={<HomeFour />} />
                            <Route path="/home-five" element={<HomeFive />} />
                            <Route path="/home-six" element={<HomeSix />} />
                            <Route path="/home-seven" element={<HomeSeven />} />
                            <Route path="/home-eight" element={<HomeEight />} />
                            <Route path="/home-dark" element={<HomeDark />} />
                            <Route path="/home-rtl" element={<HomeRtl />} /> */}
                            <Route path="/blog" element={<News />} />
                            <Route path="/blog/:id" element={<SingleNews />}/>
                            <Route path="/blog/single-news" element={<SingleNews />} />
                            <Route path="/blog/stmfigures" element={<StmFigures />} />
                            {/* <Route path="/service" element={<Service />} /> */}
                            <Route path="/gartner" element={<Gartner />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/error" element={<Error />} />
                            {/* <Route path="/shops" element={<Shops />} />
                            <Route path="/shops/shop-details" element={<ShopDetails />} /> */}
                            <Route element={<Error />} />
                        </Routes>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default MyRoutes;
