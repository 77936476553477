import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <a href="#">
                        Modules
                        <i className="fal fa-angle-down" />
                    </a>

                    <ul className="sub-menu">
                        <li>
                            <Link to="/module-workforce">Workforce</Link>
                        </li>
                        <li>
                            <Link to="/module-capex">Capex</Link>
                        </li>
                        <li>
                            <Link to="/module-revenue">Revenue</Link>
                        </li>
                        <li>
                            <Link to="/module-opex">Opex</Link>
                        </li>
                        <li>
                            <Link to="/module-balance-scorecard">
                                Balance Scorecard
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <Link to="/gartner">Gartner</Link>
                </li>
                <li>
                    <Link to="/blog">Blog</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
