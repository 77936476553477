import React from "react";
import { useForm } from "react-hook-form";
import submitForm from "../../services/contactService";

function Forms() {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        const {acceptTerms, ...sendData } = data;
        await submitForm(sendData);
    };

    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Contact us</h3>
                                <p>Tell us about your needs and we will help you to optimize your process.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        1250 Broadway 36th Floor,
                                        <br /> New York, NY 10001
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        <a href="tel:+13054585780">(+1) 305-458-5780</a>
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        <a href="mailto:info@daplan.us">info@daplan.us</a>
                                       </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href="https://www.facebook.com/QuanamCorp/">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href="ttps://twitter.com/QuanamCorp">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="ins" href="https://www.instagram.com/quanamcorp/">
                                        <i className="fab fa-instagram"></i>
                                    </a>

                                    <a className="lin"
                                       href="ttps://www.linkedin.com/company/quanam/?originalSubdomain=uy">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                    <a className="you" href="https://www.youtube.com/channel/UCedF72LeZM52VK2SBqjmqhw">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Schedule a Demo</h4>
                                <p>Looking for further information? Fill the form and start to gain time.</p>
                                <form action="#" onSubmit={handleSubmit(onSubmit)} className="row">
                                    <div className="col-md-6">
                                        <input type="text" {...(errors.nombre && {style:{border: "1px solid red"}})} {...register("nombre", {
                                            required: true
                                        })} placeholder="First Name" />
                                        {errors.nombre?.type === "required" && <span style={{ color: "red" }}> Required Field </span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" {...(errors.apellido && {style:{border: "1px solid red"}})} {...register("apellido", {
                                            required: true
                                        })} placeholder="Last Name" />
                                        {errors.apellido?.type === "required" && <span style={{ color: "red" }}> Required Field </span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            {...(errors.email && {style:{border: "1px solid red"}})}
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
                                            })}
                                            placeholder="Email Address"
                                        />
                                        {errors.email?.type === "required" && <span style={{ color: "red" }}> Required Field </span>}
                                        {errors.email?.type === "pattern" && <span style={{ color: "red" }}> Invalid Email Address </span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            {...(errors.phone && {style:{border: "1px solid red"}})}
                                            {...register("phone", {
                                                required: true
                                            })}
                                            placeholder="Phone Number"
                                        />
                                        {errors.phone?.type === "required" && <span style={{ color: "red" }}> Required Field </span>}
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" {...(errors.subject && {style:{border: "1px solid red"}})} {...register("subject", {
                                            required: true
                                        })} placeholder="Subject" />
                                        {errors.subject?.type === "required" && <span style={{ color: "red" }}> Required Field </span>}
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            {...(errors.message && {style:{border: "1px solid red"}})}
                                            {...register("message", {
                                                required: true
                                            })}
                                            placeholder="How can we help?"
                                        ></textarea>
                                        {errors.message?.type === "required" && <span style={{ color: "red" }}> Required Field </span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="acceptTerms" type="checkbox" {...register("acceptTerms", {
                                                validate: value => value
                                            })} />
                                            <label htmlFor="acceptTerms">
                                                I agree to the <a target="blank" href="/terms-demo-daplan.pdf">Terms & Conditions</a>
                                            </label>
                                            {errors.acceptTerms?.type === "validate" &&
                                                <span style={{ color: "red" }}>
                                                    You must agree to the Terms & Conditions
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Forms;
