import React from 'react';


function GartnerHomeTwo() {
    return (
        <>
            <section className="appie-about-area mb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-about-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="row">
                                    
                                    
                                    <div className="col-lg-12">
                                        <div className="appie-about-content">
                                            
                                            <h3 className="title">
                                            We were distinguished for:
                                            </h3>
                                            </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <h4 className="title">Wide service coverage </h4>
                                                    <p>
                                                    In areas such as: Analytics and BI, Data Science and Machine Learning, Master Data Management, Data Integration, Data Management, Data Quality.
                                                    </p>
                                                </div>
                                                </div>
                                            <div className="col-md-12">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <h4 className="title">Diversity of implemented technologies/products:</h4>
                                                    <p>
                                                    IBM, Tableau, Microsoft, MicroStrategy, Oracle, Cloudera, Pentaho, Informatica, Harris, etc.
                                                    </p>
                                                </div>
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <h4 className="title">Business experience:</h4>
                                                    <p>
                                                    In several verticals: Banking, Insurance, Mining, Oil and Gas, Public Sector, Telecommunications, Utilities and Energy.
                                                    </p>
                                                </div>
                                                </div>
                                            <div className="col-md-12">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check" />
                                                    </div>
                                                    <h4 className="title">Geographic coverage: </h4>
                                                    <p>
                                                    USA, Brazil and the rest of LATAM.
                                                    </p>
                                                </div>

                                            <blockquote>
                                                  <p>
                                                 “This new recognition is an honour and also a great responsibility that motivates us to continue innovating in order to provide high-quality services to our clients. I congratulate our great team of multidisciplinary professionals and I thank our clients for trusting Quanam as a strategic partner to strengthen their organizations through intelligent data analysis”
                                                </p>
                                                 <cite>Guillermo Spinelli, Partner and CEO of Data & Analytics.</cite>
                                            </blockquote>
                                               
                                                   </div>
                                                </div>
                                            </div>
                                         </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </>
    );
}

export default GartnerHomeTwo;
