import React, { useState } from "react";
import { Link } from "react-router-dom";
import thumb1 from "../../assets/images/features-thumb-1.png";
import thumb2 from "../../assets/images/features-thumb-2.png";
import thumb3 from "../../assets/images/features-thumb-3.png";
import thumb4 from "../../assets/images/features-thumb-4.png";
import thumb5 from "../../assets/images/features-thumb-5.png";

function FeaturesHomeOne({ className }) {
  
    let seleccionado = "setting";
    let isMobile = false;
    if (window.innerWidth <= 500){isMobile=true};

    if (isMobile) {
        seleccionado = "nada";
    }
    const [tab, setTab] = useState(seleccionado);
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (    
        <section
            className={`appie-features-area pt-100 ${className}`}
            id="features"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="d-block d-sm-none text-center pb-5">
                            <p>Select module, for more information</p>
                        </div>
                        <div className="appie-features-tabs-btn">
                            <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    onClick={(e) => handleClick(e, "setting")}
                                    className={`nav-link ${
                                        tab === "setting" ? "active" : ""
                                    }`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    <i className="fas fa-user-friends" />{" "}
                                    WORKFORCE
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, "report")}
                                    className={`nav-link ${
                                        tab === "report" ? "active" : ""
                                    }`}
                                    id="v-pills-profile-tab"
                                    data-toggle="pill"
                                    href="#v-pills-profile"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-hand-holding-usd" />{" "}
                                    CAPEX
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, "notice")}
                                    className={`nav-link ${
                                        tab === "notice" ? "active" : ""
                                    }`}
                                    id="v-pills-messages-tab"
                                    data-toggle="pill"
                                    href="#v-pills-messages"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-dollar-sign" /> REVENUE
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, "app")}
                                    className={`nav-link ${
                                        tab === "app" ? "active" : ""
                                    }`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-money-bill-alt" /> OPEX
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, "opex")}
                                    className={`nav-link ${
                                        tab === "opex" ? "active" : ""
                                    }`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-chart-pie" /> BALANCED
                                    SCORECARD
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={`${
                                    tab === "setting" ? "show active" : ""
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center wow animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={thumb1} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>DAPLAN PLATFORM</span>
                                            <h3 className="title">
                                                Workforce
                                                <br />
                                            </h3>
                                            <p>
                                                The workforce model allows you
                                                to have a complete vision of the
                                                payroll expenses of your
                                                company. The main objective is
                                                to be able to plan human capital
                                                expenses in a flexible and easy
                                                way by using, at the beginning
                                                of the base period, assumptions
                                                about the main variables such as
                                                salaries, bonus policies,
                                                merits, etc.
                                            </p>
                                            <Link
                                                className="main-btn"
                                                to="/module-workforce"
                                            >
                                                Learn More
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === "report" ? "show active" : ""
                                } tab-pane fade`}
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={thumb2} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>DAPLAN PLATFORM</span>
                                            <h3 className="title">
                                                Capex
                                                <br />
                                            </h3>
                                            <p>
                                                The capex model allows planning
                                                and having a complete vision of
                                                the company's assets. The main
                                                objective is to simplify the
                                                monitoring of capital costs and
                                                to know the depreciation figures
                                                of those assets through
                                                analytical reports.
                                            </p>
                                            <a
                                                className="main-btn"
                                                href="/module-capex"
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === "notice" ? "show active" : ""
                                } tab-pane fade`}
                                id="v-pills-messages"
                                role="tabpanel"
                                aria-labelledby="v-pills-messages-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={thumb3} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>DAPLAN PLATFORM</span>
                                            <h3 className="title">
                                                Revenue <br />
                                            </h3>
                                            <p>
                                                This model allows estimating
                                                units, establishing pricing and
                                                discount policies, achieving an
                                                analysis of the company's gross
                                                and net income. It has two major
                                                objectives, on the one hand, to
                                                have a solution that allows the
                                                budget to be drawn up in a
                                                decentralized way, dynamically
                                                and in an agile way; On the
                                                other hand, the income module
                                                will allow estimating units and
                                                establishing price and discount
                                                policies, achieving an analysis
                                                of the company's gross and net
                                                income.
                                            </p>
                                            <a
                                                className="main-btn"
                                                href="/module-revenue"
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === "app" ? "show active" : ""
                                } tab-pane fade`}
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={thumb4} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>DAPLAN PLATFORM</span>
                                            <h3 className="title">
                                                Opex
                                                <br />
                                            </h3>
                                            <p>
                                                This model integrates the
                                                company's operations,
                                                consolidating its total expenses
                                                and obtaining a global vision
                                                when setting objectives and
                                                making decisions. The objective
                                                is to have a solution for
                                                planning, budgeting and
                                                financial projections, fed by
                                                the other modules of the
                                                comprehensive solution
                                                (workforce, capex, revenue) and
                                                respecting the commercial rules
                                                of interaction.
                                            </p>
                                            <a
                                                className="main-btn"
                                                href="/module-opex"
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`${
                                    tab === "opex" ? "show active" : ""
                                } tab-pane fade`}
                                id="v-pills-settings"
                                role="tabpanel"
                                aria-labelledby="v-pills-settings-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={thumb5} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>DAPLAN PLATFOM</span>
                                            <h3 className="title">
                                                Balanced Scorecard <br />
                                            </h3>
                                            <p>
                                                The objective of this module is
                                                to define and monitor
                                                performance metrics related to
                                                the base modules: Workforce,
                                                Capex, OpEx and Revenue. By
                                                defining these key performance
                                                indicators (KPIs), users will
                                                know whether or not they are
                                                achieving their goals. Users can
                                                define conditional alarms and
                                                notifications relative to
                                                specific targets and thresholds.
                                                With this module, business users
                                                can make better informed
                                                decisions.
                                            </p>
                                            <a
                                                className="main-btn"
                                                href="/module-balance-scorecard"
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesHomeOne;
