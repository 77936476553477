import React from 'react';
import BlogImg1 from '../../assets/images/blog/p1.jpg';
import BlogImg2 from '../../assets/images/blog/p2.jpg';
import BlogImg3 from '../../assets/images/blog/p3.jpg';


function BlogSideBar() {
    return (
        <div className="blog-sidebar">
            <aside className="widget widget-search">
                <form className="search-form" action="#" method="post">
                    <input type="search" name="s" placeholder="Search..." />
                    <button type="submit">
                        <i className="fal fa-search"></i>
                    </button>
                </form>
            </aside>
            <aside className="widget widget-categories">
                <h3 className="widget-title">Categories</h3>
                <ul>
                    <li>
                        <a href="#">Blog</a>
                        <span>(3)</span>
                    </li>
                    <li>
                        <a href="#">News & Events</a>
                        <span>(0)</span>
                    </li>
                    <li>
                        <a href="#">Success Stories</a>
                        <span>(0)</span>
                    </li>
                    
                </ul>
            </aside>
            <aside className="widget widget-trend-post">
                <h3 className="widget-title">Popular Posts</h3>
                <div className="popular-post">
                    <a href="single-post.html">
                        <img src={BlogImg1} alt="" />
                    </a>
                    <h5>
                        <a href="single-post.html">The STM in figures</a>
                    </h5>
                   
                </div>
                <div className="popular-post">
                    <a href="single-post.html">
                        <img src={BlogImg2} alt="" />
                    </a>
                    <h5>
                        <a href="single-post.html">Banking as a service</a>
                    </h5>
                  
                </div>
                <div className="popular-post">
                    <a href="single-post.html">
                        <img src={BlogImg3} alt="" />
                    </a>
                    <h5>
                        <a href="single-post.html">The digital experience as a competitive advantage</a>
                    </h5>
                    
                </div>
               
            </aside>
            
        </div>
    );
}

export default BlogSideBar;
