import React from 'react';
import aboutThumb from '../../assets/images/about-thumb-10.png';
import aboutThumb3 from '../../assets/images/about-thumb-11.png';


function AboutOpex() {
    return (
        <>
            <section className="appie-about-3-area pt-100 pb-100" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">
                                OpEx has a set of reports that allow users to drill down or summarize along the following dimensions:
                                </h3>
                                <p>
                                <dt>
                                                                            <li>Yearly</li>
                                                                            <li>Stage</li>
                                                                            <li>Badge</li>
                                                                            <li>Organization</li>
                                                                            {/* <li>Generic dimension 1</li>
                                                                            <li>Generic dimension 2</li> */}
                                                                            </dt>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        
                                        <h5 className="title">Reduce errors</h5>
                                        <p>Regularly reviewing and auditing OPEX can help to identify and correct errors before they become significant problems.Reducing errors in OPEX can help businesses to lower costs and improve efficiency. </p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                       
                                        <h5 className="title">Multicurrency</h5>
                                        <p>Businesses need to take into account exchange rate fluctuations when creating budgets and forecasts. This may require creating multiple budget and forecast scenarios based on different exchange rate assumptions. 
                                           Daplan can handle multiple currencies and automate many of the processes involved in managing OPEX in multiple currencies.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-100 flex-column-reverse flex-lg-row">
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">Scenarios or Versions</h3>
                                <p>At the same time, users can track accounting plan variances by scenario or version. Users can easily compare actual and budgeted expenses by year, organization and/or generic dimensions.                  
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30 item-3">
                                        
                                        <h5 className="title">Deviations & Unforseen</h5>
                                        <p>The first step in managing OPEX deviations is to identify the cause of the deviation. Businesses should monitor and review their OPEX on a regular basis to ensure that they are on track and to identify any potential deviations early.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30 item-4">
                                        
                                        <h5 className="title">Commercial rules</h5>
                                        <p>In the context of operating expenses (OPEX), commercial rules can play an important role in managing costs and ensuring compliance. By understanding and complying with commercial rules, businesses can ensure that they are purchasing goods and services in a cost-effective and compliant manner, which can help to control and manage OPEX.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutOpex;
