import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import Drawer from '../Mobile/Drawer';
import AboutOpex from './AboutOpex';

function Opex() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader drawer={drawer}  action={drawerAction.toggle} />
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-top-title">
                                <h2 className="title">Opex</h2>
                                <p>This model integrates the company's operations, consolidating its total expenses and obtaining a global vision when setting objectives and making decisions. The objective is to have a solution for planning, budgeting and financial projections, fed by the other modules of the comprehensive solution (workforce, capex, revenue) and respecting the commercial rules of interaction.</p>
                                <p>This strategy allows the reduction of errors by resorting to the minimum amount of manual entries and saving time thanks to the pre-established calculations. Through the different reports, the information is reflected as an income statement.</p>
                                <p>The model has the possibility of being multi-currency. Through the relevant configurations, the data can be viewed in the local currency or in the equivalents that are defined.</p>
                                <p>In addition, you have the possibility of adding other expenses not contemplated in the original modules and thus being able to make all the adjustments that are necessary for the planning to be as expected.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="opex-appie-about-page-content">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AboutOpex />
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Opex;
