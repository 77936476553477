import axios from "axios";

// const url = "https://prod-aleph.quanam.com/api/tiendas/115/contacto";

const url = "https://webhook.site/8b98c4fa-c3e0-44f4-b99f-1c02a55fd5b5"

const submitForm = async (data) => {
    await axios.post(url, data);
};

export default submitForm;