import React from "react";
import projectThumb from "../../assets/images/project-thumb.png";

function ProjectHomeOne({ className }) {
    return (
        <>
            <section className={`appie-project-area pb-100 ${className || ""}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-project-content">
                                            <h3 className="title">
                                                Start your project now
                                            </h3>
                                            <p>
                                                Have further information and see
                                                DAPLAN in action.
                                            </p>
                                            <a
                                                className="main-btn mt-30"
                                                href="/contact"
                                            >
                                                Schedule Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="appie-project-thumb">
                                    <img src={projectThumb} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectHomeOne;
