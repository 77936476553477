import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import FooterHomeOne from "../HomeOne/FooterHomeOne";
import Drawer from "../Mobile/Drawer";
import Blog from "./Blog";
// import BlogSideBar from "./BlogSideBar";
import HeaderNews from "./HeaderNews";
import HeroNews from "./HeroNews";
import { getBlog } from "../../services/blogService";

function SingleNews() {
    const { id } = useParams();
    const [drawer, drawerAction] = useToggle(false);
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        id && getBlog(id).then(setBlog);
    }, [id]);


    return (
        <>
            {blog && <>
                <Drawer drawer={drawer} action={drawerAction.toggle} />
                <HeaderNews action={drawerAction.toggle} />
                <HeroNews
                    title="Blog"
                    breadcrumb={[
                        { link: "/", title: "home" },
                        { link: "/blog", title: "Blogs" },
                        { link: `/blog/${blog.id}`, title: blog.nombre }
                    ]}
                />
                <section className="blogpage-section">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                <Blog blog={blog} />
                            </div>
                        </div>
                    </div>
                </section>
                <FooterHomeOne />
                <BackToTop />
            </>}
        </>
    );
}

export default SingleNews;
