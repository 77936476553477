import React from 'react';
import aboutThumb from '../../assets/images/about-thumb-6.png';
import aboutThumb3 from '../../assets/images/about-thumb-7.png';
import aboutThumb4 from '../../assets/images/about-thumb-7b.png';


function AboutCapex() {
    return (
        <>
            <section className="appie-about-3-area pt-100 pb-100" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">
                                Some of the associated attributes that function as assumptions for the model:
                                </h3>
                                <p>
                                <dt>
                                                                            <li>Asset description</li>
                                                                            <li>Total cost</li>
                                                                            <li>Month / year in which it was acquired</li>
                                                                            <li>Month / year in which it is put into operation</li>
                                                                            <li>Associated project code</li>
                                                                            <li>Useful life in months</li>
                                                                            <li>Explanation of the purchase</li>
                                                                           </dt>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        
                                        <h5 className="title">Easy Monitoring</h5>
                                        <p>The goal of easy monitoring is to make it simple for companies the tracking of their assets, so they can focus on more strategic initiatives.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                        
                                        <h5 className="title">Depreciation Figures</h5>
                                        <p>This helps companies to more accurately reflect the value of their assets on their financial statements, which can be useful for investors, lenders, and other stakeholders. </p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-100 flex-column-reverse flex-lg-row">
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">The module will provide the following monthly calculations:</h3>
                                <p>
                                <dt>
                                        <li>Initial cost of the balance (initial cost)additions</li>
                                        <li>Accumulation of additions in the year (YTD additions)</li>
                                        <li>Cost in the month of service (in service)</li>
                                        <li>Accumulation of costs in service in the year (YTD in service)</li>
                                        <li>Manual user adjustments (adjustments)</li>
                                        <li>Final cost of the balance (final cost)</li>                                    
                                        <li>Initial depreciation of the period (depr beg bal)</li>
                                        <li>Depreciation of existing assets, manual entry by user (deprivation of existing assets)</li>   
                                        <li>Depreciation of additions (deprivation of additions)</li>
                                        <li>Total depreciation (total depreciation)</li>
                                        <li>Final balance sheet depreciation (depr in bal)</li>
                                        <li>Beginning balance = costs + beginning depreciation (beginning asset value)</li>
                                        <li>Ending balance = costs + ending depreciation (asset ending balance)</li>
                                    </dt>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30 item-3">
                                        
                                        <h5 className="title">Accurate Calculations</h5>
                                        <p>Accurate asset calculation is necessary for tax and regulatory compliance. Inaccurate asset calculation may lead to penalties and fines. Furthermore, it can also help the company to identify and manage risks, improve efficiency and support strategic decision making.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30 item-4">
                                        
                                        <h5 className="title">Reports & Planning</h5>
                                        <p>By planning company assets, the company can ensure that it has the necessary resources to support its operations and growth, while also managing risks and maximizing returns on investments.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                    <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />  
                                            </a>
                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                      
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="" />
                            </div>
                        </div>


                        <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">
                                All information associated with asset costs and depreciation can be viewed at the following level of detail (dimensions):
                                </h3>
                                <p>
                                <dt>
                                                                            <li>Asset type</li>
                                                                            <li>Organization</li>
                                                                            {/* <li>Generic dimension 1</li>
                                                                            <li>Generic dimension 2</li> */}
                                                                            </dt>
                                </p>
                            </div>
                         
                            <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        
                                        <h5 className="title">Greater transparency</h5>
                                        <p>Detailed information about assets can help organizations be more transparent about their operations and how they use resources, which can help build trust with stakeholders.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                        
                                        <h5 className="title">Increased accountability</h5>
                                        <p>Detailed information about assets can help organizations keep track of who is responsible for different assets and ensure that they are being used and maintained properly. </p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </section>
        </>
    );
}

export default AboutCapex;
