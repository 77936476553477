import React from "react";

function PopupVideo({ handler }) {
    /* 
     //www.youtube.com/embed/EE7NqzhMDms?autoplay=1
    */
    return (
        <div>
            <div onClick={handler} className="mfp-bg mfp-ready"></div>
            <div
                className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                tabIndex="-1"
                style={{ overflow: " hidden auto" }}
            >
                <div className="mfp-container mfp-s-ready">
                    <div className="mfp-content">
                        <div className="mfp-iframe-scaler">
                            <button
                                onClick={handler}
                                title="Close (Esc)"
                                type="button"
                                className="mfp-close"
                            >
                                ×
                            </button>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-hero-content p-4">
                                            <h2>
                                                Digital Revolution for financial
                                                Leaders
                                            </h2>
                                            <h5 className="py-3">
                                                Jun 8, 2023 - Millennium
                                                Knickerbocker Hotel - 163 E
                                                Walton Pl, Chicago.
                                            </h5>
                                            <p className="py-1">
                                                We bring together financial
                                                leaders to share an excellent
                                                worldwide success story.
                                            </p>
                                            <p className="py-1">
                                                We aim to help them identify the
                                                main challenges and issues faced
                                                by financial teams in budgeting
                                                processes: Where is the majority
                                                of manual tasks being consumed?
                                                Have you ever considered
                                                implementing predictive models
                                                in your planning process? How do
                                                you see adding value?
                                            </p>
                                            <p className="py-1">
                                                We invite you to learn how other
                                                companies have addressed these
                                                questions:
                                            </p>
                                            <dt>
                                                <li>Customer Testimonial</li>
                                                <li>
                                                    DAPLAN - the latest
                                                    financial planning tool
                                                </li>
                                                <li>
                                                    Latest trends in Data &
                                                    Analytics
                                                </li>
                                            </dt>
                                            <p className="text-center">
                                                <ul>
                                                    <li>
                                                        <a
                                                            target="blank"
                                                            href="https://www.linkedin.com/events/digitalrevolutionforfinancialle7061450087914254336/"
                                                        >
                                                            <i className="fa fa-book fa-fw" />
                                                            Register here
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={handler}>
                                                            <i className="fa fa-times fa-fw" />
                                                            Close popup
                                                        </a>
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <iframe
                                title="video"
                                className="mfp-iframe"
                                src={videoSrc}
                                frameBorder="0"
                                allowFullScreen=""
                            /> */}
                        </div>
                    </div>
                    <div className="mfp-preloader">Loading...</div>
                </div>
            </div>
        </div>
    );
}

export default PopupVideo;
