import React from 'react';
import aboutThumb from '../../assets/images/about-thumb-2.png';
import aboutThumb3 from '../../assets/images/about-thumb-3.png';


function AboutWorkforce() {
    return (
        <>
            <section className="appie-about-3-area pt-100 pb-100" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">
                                Key attributes that the module associates with each employee:
                                </h3>
                                <p>
                                <dt>
                                                                            <li>Name</li>
                                                                            <li>Job type</li>
                                                                            <li>Work code</li>
                                                                            <li>Full time equivalent</li>
                                                                            <li>Hiring year</li>
                                                                            <li>Hiring month</li>
                                                                            <li>Final month (if necessary)</li>
                                                                            <li>Salary</li>
                                                                            <li>Merit period</li>
                                                                            <li>Merit adjustment</li>
                                                                            <li>Comments</li></dt>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        
                                        <h5 className="title">Salaries</h5>
                                        <p>Have a complete projection of the cost in salaries.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                       
                                        <h5 className="title">Bonus Policies</h5>
                                        <p>Define bonus policies according to your needs.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">Reports available by the following stats:</h3>
                                <p>
                                <dt>
                                        <li>Year</li>
                                        <li>Stage</li>
                                        <li>Organization</li>
                                        {/* <li>Generic dimension 1</li>
                                        <li>Generic dimension 2</li> */}
                                                                             

                                    </dt>
                                </p>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30 item-3">
                                       
                                        <h5 className="title">Detailed & Consolidated</h5>
                                        <p>The workforce module has reports available both at a detailed and consolidated level.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30 item-4">
                                        
                                        <h5 className="title">Choose dimensions</h5>
                                        <p>You can choose if you put all or only one.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
               
            </section>
        </>
    );
}

export default AboutWorkforce;
