import React from 'react';

function HeroGartner() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-top-title">
                                <h2 className="title"> Quanam: Recognized as a Leader in Data & Analytics Services by Gartner</h2><br></br>
                                <p> We are the only Latin American company to integrate the Gartner Data & Analytics Market Guide!

The Market Guide is a list of outstanding global companies recognized by Gartner through a global survey on AI, Data and Analytics Service Providers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span></span>
           
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                             
                                
                                
                        </div>
                    
                </div>
                </div>
            </section>
        </>
    );
}

export default HeroGartner;
