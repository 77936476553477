import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import Drawer from '../Mobile/Drawer';
import AboutRevenue from './AboutRevenue';

function Revenue() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader drawer={drawer}  action={drawerAction.toggle} />
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-top-title">
                                <h2 className="title">Revenue</h2>
                                <p>This model allows estimating units, establishing pricing and discount policies, achieving an analysis of the company's gross and net income. It has two major objectives, on the one hand, to have a solution that allows the budget to be drawn up in a decentralized way, dynamically and in an agile way; On the other hand, the income module will allow estimating units and establishing price and discount policies, achieving an analysis of the company's gross and net income.</p>
                                <p>In order to anticipate changes and make timely decisions, simulations of what-if scenarios can be carried out, modifying any income and expense variable.</p>
                                <p><h5> The information entered refers to:</h5>
                                    <ul>
                                        <li>Initial discounts</li>
                                        <li>Unit price</li>
                                        <li>Direct materials and/or labor</li>
                                        <li>Indirect cost and indirect labor</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="revenue-appie-about-page-content">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AboutRevenue/>
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Revenue;
