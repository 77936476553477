import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import HeaderGartner from './HeaderGartner';
import HeroGartner from './HeroGartner';
import GartnerHomeTwo from './GartnerHomeTwo';

function Gartner() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderGartner drawer={drawer} action={drawerAction.toggle} />
            <HeroGartner />
            <br></br>
            <GartnerHomeTwo/>
            <FooterHomeOne className="appie-footer-gartner-area" />
            <BackToTop />
        </>
    );
}

export default Gartner;
