import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import Drawer from '../Mobile/Drawer';
import AboutWorkforce from './AboutWorkforce';

function Workforce() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader drawer={drawer}  action={drawerAction.toggle} />
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-top-title">
                                <h2 className="title">Workforce</h2>
                                <p>The workforce model allows you to have a complete vision of the payroll expenses that a company has.
                                <p>The main objective is to be able to plan human capital expenses in a flexible and easy way using, at the beginning of the base period, assumptions about the main variables such as salaries, bonus policies, merits, etc.</p>
</p><p>In this way, projecting the payroll is done more easily and quickly, as well as having the possibility of incorporating new charges and adjustments in the future.</p><p> Analysis is facilitated by changing HR assumptions by quickly analyzing the impact of both tax spending and spending on bonuses or other benefits.</p>
                   
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                                                               </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <AboutWorkforce />
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Workforce;
