import React from 'react';
import aboutThumb from '../../assets/images/about-thumb-8.png';
import aboutThumb3 from '../../assets/images/about-thumb-9.png';


function AboutRevenue() {
    return (
        <>
            <section className="appie-about-3-area pt-100 pb-100" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">                                
                                   
                            The Revenue module allows scenario or version analysis and you can track variance by:
                                </h3>
                                <p>
                                <dt>
                                                                            <li>Unit sold</li>
                                                                            <li>Unit price</li>
                                                                            <li>Gross income</li>
                                                                            <li>Unit cost</li>
                                                                            <li>Cost of sales</li>
                                                                            <li>Gross margin</li>
                                                                            <li>Detailed by channel, product, organization.</li>
                                                                            {/* <li>Detailed by channel, product, organization, generic dimension 1, generic dimension 2.</li> */}
                                                                            </dt>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        
                                        <h5 className="title">Optimize Revenue</h5>
                                        <p>Pricing policies can help a business optimize its revenue by adjusting prices based on factors like supply and demand, seasonality, and customer willingness to pay.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                        
                                        <h5 className="title">Rate-based bill of materials (BOM)</h5>
                                        <p>This information is crucial for pricing products, creating budgets, and making production decisions.Overall, rate-based BOM is a essential tool for managing and controlling costs in the manufacturing process.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-100 flex-column-reverse flex-lg-row">
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">
                                Finally, the user can see the year-on-year evolution of these indicators by:</h3>
                                <p>
                                <dt>
                                        <li>Channel</li>
                                        <li>Version</li>
                                        <li>Stage</li>
                                        <li>Organization</li>
                                        {/* <li>Generic dimension 1</li>
                                        <li>Generic dimension 2</li> */}
                                       
                                                                             

                                    </dt>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30 item-3">
                                        
                                        <h5 className="title">Decentralized budgets</h5>
                                        <p>Decentralized budgets allow departments to align their budgets with their specific business goals and objectives, which can lead to better decision-making and more efficient use of resources.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30 item-4">
                                        
                                        <h5 className="title">Revenue evolution tracking</h5>
                                        <p>Tracking revenue evolution can help businesses create accurate budgets and forecasts. By understanding past revenue trends, businesses can better anticipate future revenue and make informed decisions about resource allocation.</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="traffic-btn mt-50">
                                        <a className="main-btn" href="/contact">
                                            Ask for further information <i className="fal fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutRevenue;
