import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import Drawer from '../Mobile/Drawer';
import AboutBalance from './AboutBalance';

function Balance() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader drawer={drawer}  action={drawerAction.toggle} />
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-top-title">
                                <h2 className="title">Balance Scorecard</h2>
                                <p>The objective of this module is to define and monitor performance metrics related to the base modules: Workforce, Capex, OpEx and Revenue. By defining these key performance indicators (KPIs), users will know whether or not they are achieving their goals. Users can define conditional alarms and notifications relative to specific targets and thresholds. With this module, business users can make better informed decisions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="balance-appie-about-page-content">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AboutBalance/>
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Balance;
