import React from 'react';
import { Link } from 'react-router-dom';
import logoQuanam from '../../assets/images/logo-6.png';
import logo from '../../assets/images/logo.png';



function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                The proven solution that supports the financial planning and budgeting process
                                </p>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/QuanamCorp/">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/QuanamCorp">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/quanamcorp/">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/quanam/?originalSubdomain=uy">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCedF72LeZM52VK2SBqjmqhw">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Platform</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <a href="/gartner">Gartner</a>
                                    </li>
                                    {/* <li>
                                        <Link to="/news">Blog</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope" /> info@daplan.us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone" /> (+1) 305-458-5780
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> 1250 Broadway 36th Floor, New York, NY 10001
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="/contact">
                                                <i className="fa fa-book fa-fw" /> Schedule Demo
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>

                                <div className="copyright-text">
                                <a href="https://quanam.com/en/">
                                        <img src={logoQuanam} alt="" />
                                </a>

                                    <p>Copyright © 2023 DAPLAN. All rights reserved.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
