import axios from "axios";

const url = "https://aleph.quanam.com/cms/api/public/daplan/elementos";

const getBlogs = async () => (await axios.get(url, {
    params: {
        limite: 9,
        estado: "activo",
        modulo: "Blogs",
        order: "orden-desc"
    }
})).data.elementos;

const getBlog = async (id) => (await axios.get(url, {
    params: {
        id
    }
})).data.elementos[0];

export { getBlog, getBlogs };