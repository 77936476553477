import React, { useState } from 'react';
import heroThumbOne from '../../assets/images/hero-thumb-1.png';
import PopupVideo from '../PopupVideo';

function HeroHomeOne({ className }) {
    const [showVideo, setVideoValue] = useState(true);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };

    return (
        <>
            {showVideo && (
                <PopupVideo
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="appie-hero-content">
                                <span>WELCOME TO DAPLAN</span>
                                <h1 className="appie-title">
                                The Financial Tech Skill Set
                                </h1>
                                <p>
                                Ready-to-use data-driven financial solution.
                                </p>
                                <ul>
                                    <li>
                                        <a href="/contact">
                                            <i className="fa fa-book fa-fw" /> Schedule Demo
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} alt="" />
                                </div>
                                <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
